import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import infinity from '../../../../assets/icons/infinity-count.svg';

class AutoPlayButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonBackground: PIXI.Sprite;
	buttonBorder: PIXI.Sprite;
	buttonPlay: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	buttonActive: PIXI.Sprite;
	buttonFS: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	isModalActive: boolean;
	buttonLabel: PIXI.Text;
	infinityIcon?: PIXI.Sprite;
	autoSpinCount: number | string;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.isModalActive = false;
		this.button = new PIXI.Container();
		this.buttonBackground = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonBackground'].texture!
		);
		this.buttonBorder = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonBorder'].texture!
		);
		this.buttonPlay = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonPlay'].texture!
		);
		this.buttonHover = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonHover'].texture!
		);
		this.buttonActive = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonActive'].texture!
		);
		this.buttonFS = new PIXI.Sprite(
			this.app.loader.resources['autoPlayButtonFS'].texture!
		);
		this.buttonLabel = new PIXI.Text('', {
			fontSize: 22,
			fontWeight: '900',
			fill: '#151515',
		});
		this.autoSpinCount = 'OFF';
	}

	initialize = () => {
		if (!this.game.gameContainer) return;
		this.button.x = 1920 - 120 / 2 - 122;
		this.button.y = 1080 - 120 / 2 - 260;
		this.button.width = 120;
		this.button.height = 120;
		this.button.hitArea = new PIXI.Circle(0, 0, 60);

		this.buttonBackground.y = 0;
		this.buttonBackground.x = 0;

		this.buttonBorder.x = 0;
		this.buttonBorder.y = 0;
		this.buttonBorder.width = 104;
		this.buttonBorder.height = 104;

		this.buttonPlay.x = 0;
		this.buttonPlay.y = 0;
		this.buttonPlay.width = 33;
		this.buttonPlay.height = 36.27;

		this.buttonHover.x = 0;
		this.buttonHover.y = 0;
		this.buttonHover.width = 33;
		this.buttonHover.height = 36.27;
		this.buttonHover.alpha = 0;

		this.buttonActive.x = 5;
		this.buttonActive.y = 0;
		this.buttonActive.width = 57;
		this.buttonActive.height = 63.98;
		this.buttonActive.alpha = 0;

		this.buttonFS.x = -17;
		this.buttonFS.y = -17;
		this.buttonFS.width = 33;
		this.buttonFS.height = 36.27;
		this.buttonFS.alpha = 0;

		this.buttonLabel.x = -5;
		this.buttonLabel.y = 0;
		this.buttonLabel.height = 20;

		const infinityImage = PIXI.Texture.from(infinity);
		this.infinityIcon = new PIXI.Sprite(infinityImage);
		this.infinityIcon.visible = false;

		this.infinityIcon.x = -5;
		this.infinityIcon.y = 0;
		this.infinityIcon.scale.set(1.8);

		this.buttonBackground.anchor.set(0.5);
		this.buttonPlay.anchor.set(0.5);
		this.buttonHover.anchor.set(0.5);
		this.buttonActive.anchor.set(0.5);
		this.buttonBorder.anchor.set(0.5);
		this.buttonLabel.anchor.set(0.5);
		this.infinityIcon.anchor.set(0.5);

		this.game.gameContainer.addChild(this.button!);

		this.button.addChild(this.buttonBackground!);
		this.button.addChild(this.buttonBorder!);
		this.button.addChild(this.buttonPlay!);
		this.button.addChild(this.buttonHover!);
		this.button.addChild(this.buttonActive!);
		this.button.addChild(this.buttonFS!);
		this.buttonActive.addChild(this.buttonLabel!);
		this.buttonActive.addChild(this.infinityIcon!);
		this.button.interactive = true;
		this.button.buttonMode = true;
		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', e => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
	};

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.doPointerDown(this.app);
		}
	};

	setButtonState = (isActive: boolean) => {
		if(this.game.assetsController?.isFs){
			this.button.visible = false;
		} else {
			this.button.visible = true;
		};
		if (isActive) {
			if(this.game.assetsController?.isFs){
				this.buttonBackground.alpha = 0;
				this.buttonBorder.alpha = 0;
				this.buttonFS.alpha = 0;
			} else this.buttonActive.alpha = 1;

			this.buttonPlay.visible = false;
			this.buttonHover.visible = false;
		} else {
			this.buttonBackground.alpha = 1;
			this.buttonBorder.alpha = 1;
			this.buttonActive.alpha = 0;
			this.buttonFS.alpha = 0;
			this.buttonPlay.visible = true;
			this.buttonHover.visible = true;
		}
	};

	setFSButtonState = (isActive: boolean) => {
		if (isActive) {
			this.button.alpha = 1;
			this.button.interactive = true;
			this.button.buttonMode = true;
		} else {
			this.button.alpha = 0.5;
			this.button.interactive = false;
			this.button.buttonMode = false;
		}
	};

	setSpinCounter = (value: number | string) => {
		this.buttonLabel.text = value;
		this.buttonLabel.alpha = 0;
		if(this.game.assetsController?.isFs === false){
			this.buttonLabel.alpha = 1;
			this.autoSpinCount = value;
			this.game.slotMachine!.changeButtonState(false);
		}
		if (value === 'ထ') {
			this.buttonLabel.visible = false;
			this.infinityIcon!.visible = true;
		} else {
			this.buttonLabel.visible = true;
			this.infinityIcon!.visible = false;
		}
	};

	closeModal(isActive: boolean) {
		this.isModalActive = isActive;
	}

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonPlay,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonPlay,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonPlay,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.game.soundController.btn_ui?.play();
		this.isModalActive = !this.isModalActive;
		let wiggleTween = gsap.timeline();
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		this.game.soundController.btn_hover_on?.play();
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonBorder,
			0.3,
			{ rotation: this.buttonBorder.rotation },
			{ rotation: this.buttonBorder.rotation + Math.PI, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		this.game.soundController.btn_hover_off?.play();
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonBorder,
			0.3,
			{ rotation: this.buttonBorder.rotation },
			{ rotation: this.buttonBorder.rotation - Math.PI, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonPlay,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default AutoPlayButton;
