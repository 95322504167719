import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';

class CloseButton {
	app: Application;
	game: PixiGame;
	width: number;
	height: number;
	button: PIXI.Container;
	buttonDefault: PIXI.Sprite;
	pointerIsDown: boolean;
	frame: PIXI.Sprite;
	label: PIXI.Sprite;

	constructor(game: PixiGame, app: Application, width: number, height: number) {
		this.game = game;
		this.app = app;
		this.width = width;
		this.height = height;
		this.pointerIsDown = false;
		this.frame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalCloseFrame'].texture!
		);
		this.buttonDefault = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalCloseBg'].texture!
		);
		this.label = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalCloseX'].texture!
		);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.button.width = this.width;
		this.button.height = this.height;

		this.frame.width = this.width;
		this.frame.height = this.height;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonDefault.x = this.width / 2 - this.buttonDefault.width / 2;
		this.buttonDefault.y = this.height / 2 - this.buttonDefault.height / 2;

		this.label.x = this.buttonDefault.width / 2 - this.label.width / 2;
		this.label.y = this.buttonDefault.height / 2 - this.label.height / 2;

		this.button.addChild(this.frame);
		this.frame.addChild(this.buttonDefault!);
		this.buttonDefault.addChild(this.label!);

		this.button
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', e => this.leftClick(e));
	};

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.doPointerDown(this.app);
		}
	};

	doPointerDown(app: Application) {
		this.game.soundController.btn_ui?.play();
		this.pointerIsDown = true;
	}
}

export default CloseButton;
