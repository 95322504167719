import { useEffect, useState } from "react";
import { PixiGame } from "..";
import { EventType, useEventReceiver } from "../../../GameEventBus";


const useHandlerGameWin = (game: PixiGame) => {
  const [gameWin, setGameWin] = useState(0);
  const [newGameWin, setNewGameWin] = useState(0)

  useEventReceiver<number>(EventType.GAME_WIN_VALUE, (value) => {
    if (value !== undefined) {
      setNewGameWin((prev) => prev + value)
      setGameWin((prev)=>prev + value);
    } else {
      setNewGameWin(0)
    }
  });

  useEffect(() => {
    game.assetsController?.winInput?.changeValue(newGameWin);
    game.assetsController?.winInputLandscape?.changeValueLandscape(newGameWin)
    game.assetsController?.winInputPortret?.changeValue(newGameWin)
  }, [game.assetsController, newGameWin]);

  return { gameWin, newGameWin }
}

export default useHandlerGameWin