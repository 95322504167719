import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { api } from '../../../../App';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import {EventType, getEvent} from "../../../../GameEventBus";
import CloseButton from '../BuyBonusModal/CloseBtn';
import { buyBonusValueStyles } from '../BuyBonusModal/styles';

class BuyMinBonusModal {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Sprite;
	title: PIXI.Sprite;
	minCost: PIXI.Text;
	minBonusAmount: PIXI.Text;
	minBonusSprite: PIXI.Sprite;
	betValue: number | string;
	closeButton?: CloseButton;
	confirmButtonMin?: ConfirmBuyButton;
	disclaimButtonMin?: ConfirmBuyButton;
	isModalOpen: boolean;
	balance: number;
	frame: PIXI.Sprite;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.balance = 0;
		this.betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.frame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalFrame'].texture!
		);
		this.background = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalBg'].texture!
		);
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Sprite(
			this.app.loader.resources['buyBonusConfirmTitle'].texture!
		);
		this.minCost = new PIXI.Text(`${Math.trunc((100 * Number(this.betValue)))} USD`, buyBonusValueStyles);

		this.minBonusAmount = new PIXI.Text('6-10 FREE SPINS', buyBonusValueStyles);

		this.minBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['buyBonusScatter'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;
		this.modal.sortableChildren = true;
		
		this.modal.width = 920;
		this.modal.height = 568;
		
		this.frame.width = 920;
		this.frame.height = 568;
		this.frame.zIndex = 1;
		this.frame.x = 10;
		
		this.background.width = 868;
		this.background.height = 509;
		this.background.x = (this.frame.width - this.background.width) / 2 + 10;
		this.background.y = (this.frame.height - this.background.height) / 2;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 15;

		this.title.x = (this.background.width - this.title.width) / 2;
		this.title.y = 20;

		this.minCost.x = (this.background.width - this.minCost.width) / 2;
		this.minCost.y = 370;

		this.minBonusAmount.x = (this.background.width - this.minBonusAmount.width) / 2;
		this.minBonusAmount.y = 160;

		this.minBonusSprite.x = (this.background.width - this.minBonusSprite.width) / 2;
		this.minBonusSprite.y = 210;

		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.frame!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.minCost!);
		this.background.addChild(this.minBonusAmount!);
		this.background.addChild(this.minBonusSprite);
	};

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	confirmBuy = async (isActive: boolean) => {
		this.betValue = (this.game.initialData?.getBetValue() || 1)
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}

		if (this.balance < ((100 * Number(this.betValue)) + Number(this.betValue))) {
			getEvent(EventType.OUT_OF_MONEY).send(true)
		} else {
			this.game.assetsController?.autoPlayButton?.setFSButtonState(false)
			this.game.slotMachine?.changeButtonState(true)
			const buyBonus = await api.auth.buyBonus(0, (10000 * Number(this.betValue)), Number(this.betValue) * 100);
			getEvent(EventType.GET_BALANCE).send((buyBonus.account.value / 100) + Number(this.betValue))
			await this.game.slotMachine?.onBonusSpin(buyBonus)
		}
	};

	disclaimBuy = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	setupButtons = async () => {
		this.closeButton = new CloseButton(
			this.game,
			this.app,
			96,
			77
		);

		this.confirmButtonMin = new ConfirmBuyButton(
			this.game,
			this.app,
			211,
			79,
			'YES'
		);

		this.disclaimButtonMin = new ConfirmBuyButton(
			this.game,
			this.app,
			211,
			79,
			'NO'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMin, this.confirmBuy, false);
		this.onClickAndTouch(this.disclaimButtonMin, this.disclaimBuy, false);

		// this.onClickAndTouch(
		// 	this.closeButton,
		// 	this.game.assetsController?.buyMinBonusModalPortrait!.showModal!,
		// 	false
		// );
		// this.onClickAndTouch(
		// 	this.confirmButtonMin,
		// 	this.game.assetsController?.buyMinBonusModalPortrait!.confirmBuy!,
		// 	false
		// );
		// this.onClickAndTouch(
		// 	this.disclaimButtonMin,
		// 	this.game.assetsController?.buyMinBonusModalPortrait!.disclaimBuy!,
		// 	false
		// );

		await this.closeButton.initialize();
		await this.confirmButtonMin.initialize();
		await this.disclaimButtonMin.initialize();

		this.closeButton.button.x = 920 - 96;
		this.closeButton.button.y = 10;

		this.disclaimButtonMin.button.x = this.background.width - this.disclaimButtonMin.width * 1.5;
		this.disclaimButtonMin.button.y = 370;

		this.confirmButtonMin.button.x = this.confirmButtonMin.width / 2;
		this.confirmButtonMin.button.y = 370;

		this.frame.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMin.button!);
		this.background.addChild(this.disclaimButtonMin.button!);
	};

	changeBuyMinBonusModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyMinBonusModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}

	getBalance = (value: number) => {
		this.balance = value;
	};

	changeMinCost = (betValue:number) => {
		this.minCost.text = `${Math.trunc((100 * betValue))} USD`
		this.betValue = betValue;
	}
}

export default BuyMinBonusModal;
