import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import CloseButton from './CloseBtn';
import { BetInput } from '../BetInput';
import { buyBonusValueStyles } from './styles';

class BuyBonusModal {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Sprite;
	frame: PIXI.Sprite;
	title: PIXI.Sprite;
	maxCost: PIXI.Text;
	minCost: PIXI.Text;
	maxBonusAmount: PIXI.Text;
	minBonusAmount: PIXI.Text;
	maxBonusSprite: PIXI.Sprite;
	minBonusSprite: PIXI.Sprite;
	betInput?: BetInput;
	closeButton?: CloseButton;
	confirmButtonMax?: ConfirmBuyButton;
	confirmButtonMin?: ConfirmBuyButton;
	isModalOpen: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		const betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.app = app;
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.frame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalFrame'].texture!
		);
		this.background = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalBg'].texture!
		);
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Sprite(
			this.app.loader.resources['buyBonusModalTitle'].texture!
		);
		this.maxCost = new PIXI.Text(`${Math.trunc((400 * Number(betValue)))} USD`, buyBonusValueStyles);

		this.minCost = new PIXI.Text(`${Math.trunc((100 * Number(betValue)))} USD`, buyBonusValueStyles);

		this.maxBonusAmount = new PIXI.Text('10 FREE SPINS', buyBonusValueStyles);

		this.minBonusAmount = new PIXI.Text('6-10 FREE SPINS', buyBonusValueStyles); 

		this.maxBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['buyBonusMax'].texture!
		);

		this.minBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['buyBonusMin'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;
		this.modal.sortableChildren = true;
		
		this.modal.width = 920;
		this.modal.height = 568;
		
		this.frame.width = 920;
		this.frame.height = 568;
		this.frame.zIndex = 1;
		this.frame.x = 10;
		
		this.background.width = 868;
		this.background.height = 509;
		this.background.x = (this.frame.width - this.background.width) / 2 + 10;
		this.background.y = (this.frame.height - this.background.height) / 2;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 15;

		this.title.position.set(180, 15);

		this.maxCost.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxCost.width/2;
		this.maxCost.y = 320;

		this.minCost.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minCost.width/1.9;
		this.minCost.y = 320;

		this.minBonusAmount.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minBonusAmount.width/2;
		this.minBonusAmount.y = 100;

		this.maxBonusAmount.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxBonusAmount.width/2;
		this.maxBonusAmount.y = 100;

		this.maxBonusSprite.scale.set(1);
		this.maxBonusSprite.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxBonusSprite.width/2;
		this.maxBonusSprite.y = 140;

		this.minBonusSprite.scale.set(1);
		this.minBonusSprite.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minBonusSprite.width/2;
		this.minBonusSprite.y = 140;

		this.betInput = new BetInput(
            (this.game.initialData?.getBetValue() || 1).toFixed(2),
            167,
            this.app,
            this.game,
            this.game.initialData?.getBetValueIndex() || 0
        );
		await this.betInput.initialize();
		this.betInput.input?.position.set((this.background.width - this.betInput.width) / 2, 450);

		this.background.addChild(this.betInput.input!);
		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.frame!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.maxCost!);
		this.background.addChild(this.minCost!);
		this.background.addChild(this.minBonusAmount!);
		this.background.addChild(this.maxBonusAmount!);
		this.background.addChild(this.maxBonusSprite);
		this.background.addChild(this.minBonusSprite);
	};

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	showMaxModal = async (isActive: boolean) => {
		await this.showModal(false);
		this.game.gameContainer.interactiveChildren = false;
		this.game.assetsController?.buyMaxBonusModal?.showModal(true);
	};

	showMinModal = async (isActive: boolean) => {
		await this.showModal(false)
		this.game.gameContainer.interactiveChildren = false;
		this.game.assetsController?.buyMinBonusModal?.showModal(true);
	};

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	changeMinCost = (betValue:number) => {
		this.minCost.text = `${Math.trunc((100 * betValue))} USD`
	}
	changeMaxCost = (betValue:number) => {
		this.maxCost.text = `${Math.trunc((400 * betValue))} USD`
	}

	setupButtons = async () => {
		this.closeButton = new CloseButton(
			this.game,
			this.app,
			96,
			77
		);
		this.confirmButtonMax = new ConfirmBuyButton(
			this.game,
			this.app,
			211,
			79,
			'BUY'
		);

		this.confirmButtonMin = new ConfirmBuyButton(
			this.game,
			this.app,
			211,
			79,
			'BUY'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMax, this.showMaxModal, false);
		this.onClickAndTouch(this.confirmButtonMin, this.showMinModal, false);

		// this.onClickAndTouch(
		// 	this.closeButton,
		// 	this.game.assetsController?.buyBonusModalPortrait!.showModal!,
		// 	false
		// );
		// this.onClickAndTouch(
		// 	this.confirmButtonMax,
		// 	this.game.assetsController?.buyBonusModalPortrait!.showMaxModal!,
		// 	false
		// );
		// this.onClickAndTouch(
		// 	this.confirmButtonMin,
		// 	this.game.assetsController?.buyBonusModalPortrait!.showMinModal!,
		// 	false
		// );

		await this.closeButton.initialize();
		await this.confirmButtonMax.initialize();
		await this.confirmButtonMin.initialize();

		this.closeButton.button.x = 920 - 96;
		this.closeButton.button.y = 10;

		this.confirmButtonMax.button.x = this.background.width - this.confirmButtonMax.width * 1.5;
		this.confirmButtonMax.button.y = 370;

		this.confirmButtonMin.button.x = this.confirmButtonMin.width / 2;
		this.confirmButtonMin.button.y = 370;

		this.frame.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMax.button!);
		this.background.addChild(this.confirmButtonMin.button!);
	};

	changeBuyBonusModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyBonusModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}
}

export default BuyBonusModal;
