import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../..';
import { EventType, getEvent } from '../../../../GameEventBus';
import { buyBonusValueStyles } from '../BuyBonusModal/styles';

export class BetInput {
	app: Application;
	game: PixiGame;
	width: number;
	value?: string | number;
	input: PIXI.Container;
	text: PIXI.Text;
	pointerIsDown: boolean;
	plusButton: PIXI.Container;
	minusButton: PIXI.Container;
	backgroundInput: PIXI.Sprite;
	backgroundFrame: PIXI.Sprite;
	plusButtonText: PIXI.Sprite;
	plusButtonFrame: PIXI.Sprite;
	plusButtonBg: PIXI.Sprite;
	minusButtonText: PIXI.Sprite;
	minusButtonFrame: PIXI.Sprite;
	minusButtonBg: PIXI.Sprite;
	totalBetList: number[];
	selectedBetIndex: number;
	currentBetValue: number;
	betValue: number;
	betValueIndex?: number;

	constructor(
		value: string | number,
		width: number,
		app: Application,
		game: PixiGame,
		betValueIndex?: number
	) {
		this.betValue = Number(value);
		this.totalBetList = [];
		this.selectedBetIndex = 0;
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.app = app;
		this.game = game;
		this.plusButton = new PIXI.Container();
		this.minusButton = new PIXI.Container();
		this.width = width;
		this.pointerIsDown = false;
		this.plusButtonText = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputPlus'].texture!
		);
		this.plusButtonFrame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputFrame'].texture!
		);
		this.plusButtonBg = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputBg'].texture!
		);
		this.minusButtonText = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputMinus'].texture!
		);
		this.minusButtonFrame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputFrame'].texture!
		);
		this.minusButtonBg = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetInputBg'].texture!
		);
		this.betValueIndex = betValueIndex;
		this.value =
			this.betValueIndex! === 0
				? this.totalBetList[this.betValueIndex!]
				: `${this.totalBetList[this.betValueIndex!]} USD`;
		this.backgroundFrame = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetFrame'].texture!
		);
		this.backgroundInput = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBetBg'].texture!
		);
		this.text = new PIXI.Text(this.value, buyBonusValueStyles);
		this.input = new PIXI.Container();
	}

	initialize = async () => {
		this.text.resolution = 2;
		this.input.addChild(this.backgroundFrame, this.text);
		this.backgroundFrame.addChild(this.backgroundInput);
		this.backgroundInput.anchor.set(0.5);
		this.backgroundInput.position.set(
			this.backgroundFrame.width / 2,
			this.backgroundFrame.height / 2
		);

		this.text.anchor.set(0.5, 0.5);

		this.text.x = this.backgroundFrame.width / 2;
		this.text.y = this.backgroundFrame.height / 2 + 2;

		this.initializeMinusButton();
		this.initializePlusButton();
	};

	initializeMinusButton = () => {
		this.minusButton
			.on('pointerup', () => this.doMinusPointerUp(this.app))
			.on('touchstart', () => this.doMinusPointerDown(this.app))
			.on('click', e => this.leftClickMinus(e));

		this.minusButton.interactive = true;
		this.minusButton.buttonMode = true;
		this.minusButton.sortableChildren = true;

		this.input?.addChild(this.minusButton);
		this.minusButton.addChild(this.minusButtonText!);
		this.minusButton.addChild(this.minusButtonFrame!);
		this.minusButton.addChild(this.minusButtonBg!);

		this.minusButtonText.anchor.set(0.5);
		this.minusButtonFrame.anchor.set(0.5);
		this.minusButtonBg.anchor.set(0.5);
		this.minusButtonText.zIndex = 3;
		this.minusButtonFrame.zIndex = 1;
		this.minusButtonBg.zIndex = 2;

		this.minusButton.x = -this.minusButton.width / 2;
		this.minusButton.y = this.minusButton.height / 2;
	};

	leftClickMinus = (e: any) => {
		if (this.game.slotMachine!.isRunning === false) {
			if (e.data.button === 0) {
				this.doMinusPointerDown(this.app);
			}
		}
	};

	initializePlusButton = () => {
		this.plusButton
			.on('pointerup', () => this.doPlusPointerUp(this.app))
			.on('touchstart', () => this.doPlusPointerDown(this.app))
			.on('click', e => this.leftClickPlus(e));

		this.plusButton.interactive = true;
		this.plusButton.buttonMode = true;
		this.plusButton.sortableChildren = true;

		this.input?.addChild(this.plusButton);
		this.plusButton.addChild(this.plusButtonText);
		this.plusButton.addChild(this.plusButtonFrame);
		this.plusButton.addChild(this.plusButtonBg);

		this.plusButtonText.anchor.set(0.5);
		this.plusButtonFrame.anchor.set(0.5);
		this.plusButtonBg.anchor.set(0.5);
		this.plusButtonText.zIndex = 3;
		this.plusButtonFrame.zIndex = 1;
		this.plusButtonBg.zIndex = 2;

		this.plusButton.x = this.plusButton.width / 2 + this.backgroundFrame.width;
		this.plusButton.y = this.plusButton.height / 2;
	};

	leftClickPlus = (e: any) => {
		if (e.data.button === 0) {
			this.doPlusPointerDown(this.app);
		}
	};

	setTotalBetList(list: number[]) {
		this.totalBetList = list;
		this.selectedBetIndex =
			this.game.initialData?.getBetValueIndex() ||
			this.totalBetList.findIndex(item => item === Math.trunc(this.betValue));
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.betValue = this.currentBetValue;
		getEvent(EventType.CURRENT_BET_INDEX).send(this.selectedBetIndex);
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.game.initialData?.setBetValue(this.currentBetValue);
		this.game.slotMachine?.changeBetValue(this.currentBetValue);
		this.text.text = `${this.betValue} USD`;
	}

	changeValue = (value: number) => {
		this.text.text = `${value} USD`;
	};

	changeValueLandscape = (value: number) => {
		this.text.text = `${value} USD`;
	};

	doMinusPointerUp(app: Application) {
		if (this.selectedBetIndex === 0) {
			this.minusButton.buttonMode = false;
		}
		this.plusButton.buttonMode = true;
		this.pointerIsDown = false;
	}

	doPlusPointerUp(app: Application) {
		if (this.selectedBetIndex === this.totalBetList.length - 1) {
			this.plusButton.buttonMode = false;
		}
		this.minusButton.buttonMode = true;
		this.pointerIsDown = false;
	}

	doMinusPointerDown(app: Application) {
		if (this.pointerIsDown) return;
		if (this.selectedBetIndex !== 0) {
			this.game.soundController.btn_ui?.play();
		}
		if (this.selectedBetIndex > 0) {
			this.selectedBetIndex--;
		} else if (this.selectedBetIndex === 0) {
			this.selectedBetIndex = 0;
		}
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		if (this.currentBetValue <= 0) return;
		if (
			this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false
		) {
			this.text.text = `${this.currentBetValue} USD`;
			this.game.assetsController?.betModal?.changeBetValue(
				this.currentBetValue
			);
			this.game.slotMachine?.changeBetValue(this.currentBetValue);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(
				this.currentBetValue
			);
			this.game.assetsController?.buyBonusModal?.changeMinCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue
			// );
			// this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue
			// );
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue
			// );
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue
			// );
		} else {
			this.text.text = `${(this.currentBetValue * 1.25)} USD`;
			this.game.assetsController?.betModal?.changeBetValue(
				this.currentBetValue * 1.25
			);
			this.game.slotMachine?.changeBetValue(this.currentBetValue * 1.25);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(
				this.currentBetValue * 1.25
			);
			this.game.assetsController?.buyBonusModal?.changeMinCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue * 1.25
			// );
			// this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue * 1.25
			// );
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue * 1.25
			// );
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue * 1.25
			// );
		}
		getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue);
		this.pointerIsDown = true;
	}

	doPlusPointerDown(app: Application) {
		if (this.pointerIsDown) return;
		if (this.selectedBetIndex !== this.totalBetList.length - 1) {
			this.game.soundController.btn_ui?.play();
		}
		if (
			this.selectedBetIndex < this.totalBetList.length - 1 &&
			this.selectedBetIndex !== this.totalBetList.length - 1
		) {
			this.selectedBetIndex++;
		}
		this.game.initialData?.setBetValueIndex(this.selectedBetIndex);
		this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		if (
			this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === false
		) {
			this.text.text = `${this.currentBetValue} USD`;
			this.game.assetsController?.betModal?.changeBetValue(
				this.currentBetValue
			);
			this.game.slotMachine?.changeBetValue(this.currentBetValue);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(
				this.currentBetValue
			);
			this.game.assetsController?.buyBonusModal?.changeMinCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue
			// );
			// this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue
			// );
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue
			// );
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(
				this.currentBetValue
			);
			// this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue
			// );
		} else {
			this.text.text = `${(this.currentBetValue * 1.25)} USD`;
			this.game.assetsController?.betModal?.changeBetValue(
				this.currentBetValue * 1.25
			);
			this.game.slotMachine?.changeBetValue(this.currentBetValue * 1.25);
			this.game.assetsController?.buyBonusModal?.changeMaxCost(
				this.currentBetValue * 1.25
			);
			this.game.assetsController?.buyBonusModal?.changeMinCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue * 1.25
			// );
			// this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue * 1.25
			// );
			this.game.assetsController?.buyMinBonusModal?.changeMinCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(
			// 	this.currentBetValue * 1.25
			// );
			this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(
				this.currentBetValue * 1.25
			);
			// this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(
			// 	this.currentBetValue * 1.25
			// );
		}
		getEvent(EventType.GAME_BET_VALUE).send(this.game.slotMachine?.betValue);
		this.pointerIsDown = true;
	}
}
