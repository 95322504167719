import {SlotMachine} from "./SlotMachine";
import {SymbolItem} from "./SymbolItem";


export interface Cords {
    x: number,
    y: number,
}

export interface AntisipationData {
    startFrom: number,
    positions: Cords[]
    isWilds: boolean,
    scattersX?: number[]
}

export class SlotEvents {
    slotMachine: SlotMachine

    constructor(slotMachine: SlotMachine) {
        this.slotMachine = slotMachine
    }

    getAntisipationPositions(reels: any[], wildMap?: number[][], isFs = false, isExtraSymbols = false): AntisipationData | undefined {
        let scatterAmount = 0
        let wildSum = 0
        if (!reels || !reels.length || this.slotMachine.game.type === 'bonusGame') return
        const scatters: number[] = []

        const sumOfWildToDepict = isFs ? 100 : 10

        let antisipationPossitions: Cords[] = []

        if (wildMap) {
            wildMap.forEach((wild) => {
                const sum = wild.reduce((prev, cur) => prev + cur)
                wildSum += sum
            })
        }

        console.log(`SUM OF WILDS`, wildSum)
        let skipReel: undefined | number = undefined
        for (let i = 0; i < reels.length; i++) {
            const symbols: number[] = reels[i]
            for (let j = 0; j < symbols.length; j++) {
                if (scatterAmount >= 2 || wildSum > sumOfWildToDepict) {
                    if(i !== skipReel) {
                        antisipationPossitions.push({x: i, y: j})
                    }
                }
                const symbolId = symbols[j]
                if (symbolId === 2) {
                    scatters.push(i)
                    scatterAmount++
                    if (scatterAmount === 2) {
                        skipReel = i
                    }
                }
            }
        }

        if (scatterAmount < 2 || !antisipationPossitions[0] || !antisipationPossitions.length) {
            if(isExtraSymbols && scatterAmount >= 2) {
                antisipationPossitions = []
                for (let i = 0; i < reels.length; i++) {
                    const symbols: number[] = reels[i]
                    for (let j = 0; j < symbols.length; j++) {
                        antisipationPossitions.push({x: i, y: j})
                    }
                }

                return {
                    startFrom: 0,
                    positions: antisipationPossitions,
                    isWilds: wildSum > sumOfWildToDepict,
                    scattersX: scatters
                }
            }
            if (wildSum < sumOfWildToDepict) return
        }


        return {
            startFrom: !isExtraSymbols ? antisipationPossitions[0].x : 0,
            positions: antisipationPossitions,
            isWilds: wildSum > sumOfWildToDepict,
            scattersX: scatters
        }

    }
    showAntisipation = (positions: Cords[], scatters?: number[], isExtraSymbols = false) => {
        if(this.slotMachine.game.type === 'bonusGame') return
        const antisipatorBase = this.slotMachine.game.assetsController?.antisipator
        if (!antisipatorBase) return

        positions.forEach((cords) => {
                const antisipator = antisipatorBase.copy()
                this.slotMachine.antisipatorContainer?.addChild(antisipator.item)
                antisipator.item.x = 110 + 233 * cords.x
                antisipator.item.y = 353 + 233 * cords.y
        })

        const timer = setTimeout(() => {
            clearTimeout(timer)
            this.slotMachine.antisipatorContainer?.removeChildren()
        }, 1000)
    }

}

