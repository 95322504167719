import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';

class BuyBonusButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonBackground: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	buttonLabel: PIXI.Sprite;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.buttonBackground = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBntFrame'].texture!
		);
		this.buttonLabel = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBntTitle'].texture!
		);
		this.buttonDefault = new PIXI.Sprite(
			this.app.loader.resources['buyBonusBntBg'].texture!
		);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.button.x = 212;
		this.button.y = 448;
		this.button.width = 220;
		this.button.height = 200;

		this.buttonBackground.x = 0;
		this.buttonBackground.y = 0;
		this.buttonBackground.width = 220;
		this.buttonBackground.height = 200;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonLabel.anchor.set(0.5);
		this.buttonBackground.anchor.set(0.5);
		this.buttonDefault.anchor.set(0.5);

		this.button.addChild(this.buttonBackground!);
		this.buttonBackground.addChild(this.buttonDefault!);
		this.buttonDefault.addChild(this.buttonLabel!);

		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
		this.game.gameContainer.addChild(this.button);
	};

	leftClick = (e:any) => {
		if(e.data.button === 0){
			this.doPointerDown(this.app)
		};
	}

	doPointerUp(app: Application) {
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.game.soundController.btn_ui?.play()
		this.game.assetsController?.buyBonusModal?.showModal(true);
		// this.game.assetsController?.buyBonusModalPortrait?.showModal(true);
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		this.game.soundController.bonus_hover?.play()
		this.pointerIsOver = true;
	}

	changeButtonState(state: boolean){
		this.button.interactive = state;
		this.button.buttonMode = state;
		this.button.alpha= state ? 1 : 0.5;
	}

	doPointerOut(app: Application) {
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}

	doDefaultVisible(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.to(
			this.buttonDefault,
			1,
			{ alpha: 1 },
		);
	}

	doButtonDisabled(app: Application) {
		this.pointerIsOver = true;
	}

	doButtonEnabled(app: Application) {
		this.pointerIsOver = true;
	}

	setBuyBonusButtonState(state: boolean){
		this.button.interactive = state;
		this.button.buttonMode = state;
	}
}

export default BuyBonusButton;
