import { FC } from 'react';
import styled from 'styled-components';

import playBtnBorder from '../../../assets/img/auto-play-modal/auto-play-btn-border.png';
import playIcon from '../../../assets/img/auto-play-modal/auto-play-default.svg';
import closeIcon from '../../../assets/icons/close-arrow-down.svg';
import infinityIcon from '../../../assets/icons/infinity-count.svg';
import {PixiGame} from "../../../features/Game";

interface IAutoPlayHeader {
	spinCount: number | string | any;
	closeModal: () => void;
	startAutoPlay: () => void;
	game: PixiGame;
}

const AutoPlayHeader: FC<IAutoPlayHeader> = ({
	spinCount,
	startAutoPlay,
	closeModal,
	game,
}) => {
	return (
		<Header>
			<CloseButton>
				<img src={closeIcon} alt='Close' onClick={closeModal} />
			</CloseButton>
			<HeaderTitle>
				<h2>AUTO PLAY</h2>
			</HeaderTitle>
			<HeaderButton disabled={game?.slotMachine?.isRunning} className={game?.slotMachine?.isRunning ? 'disable' : ''} onClick={startAutoPlay}>
				<HeaderButtonBody>
					{spinCount === 'ထ' ? (
						<img src={infinityIcon} alt='Infinity' />
					) : (
						spinCount
					)}
				</HeaderButtonBody>
			</HeaderButton>
		</Header>
	);
};

export default AutoPlayHeader;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 8px;
`;

const HeaderTitle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	h2 {
		color: #e2f4f2;
		font-weight: 700;
		font-size: 17px;
		line-height: 24px;
	}
	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #7c8584;
	}
`;

const HeaderButton = styled.button`
	background: url(${playIcon}) no-repeat;
	background-position: 60% 50%;
	color: #151515;
	font-family: 'Rubik';
	border: 0;
	font-weight: 700;
	font-size: 11px;
	line-height: 15px;
	cursor: pointer;
	&.disable{
		opacity: 0.5;
	}
`;

const HeaderButtonBody = styled.div`
	padding-top: 1px;
	padding-left: 4px;
	background: url(${playBtnBorder}) no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
`;

const CloseButton = styled.div`
	width: 33px;
	height: 27px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	img {
		cursor: pointer;
	}
`;
