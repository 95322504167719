import { Application } from 'pixi.js';
import buyBonusBetBg from '../assets/buy-bonus/bet_bg.png';
import buyBonusBetFrame from '../assets/buy-bonus/bet_frame.png';
import buyBonusBetInputBg from '../assets/buy-bonus/bet_input_bg.png';
import buyBonusBetInputFrame from '../assets/buy-bonus/bet_input_frame.png';
import buyBonusBetInputMinus from '../assets/buy-bonus/bet_input_minus.png';
import buyBonusBetInputPlus from '../assets/buy-bonus/bet_input_plus.png';
import buyBonusBntBg from '../assets/buy-bonus/buy_bonus_btn_bg.png';
import buyBonusBntFrame from '../assets/buy-bonus/buy_bonus_btn_frame.png';
import buyBonusBntTitle from '../assets/buy-bonus/buy_bonus_btn_title.png';
import buyBonusModalTitle from '../assets/buy-bonus/buy_bonus_modal_title.png';
import buyBonusModalCloseBg from '../assets/buy-bonus/close_btn_bg.png';
import buyBonusModalCloseFrame from '../assets/buy-bonus/close_btn_frame.png';
import buyBonusModalCloseX from '../assets/buy-bonus/close_btn_x.png';
import buyBonusModalBg from '../assets/buy-bonus/confirm_modal_bg.png';
import buyBonusModalFrame from '../assets/buy-bonus/confirm_modal_frame.png';
import buyBonusScatter from '../assets/buy-bonus/confirm_modal_scatter.png';
import buyBonusConfirmTitle from '../assets/buy-bonus/confirm_modal_title.png';
import buyBonusConfirmBtnBg from '../assets/buy-bonus/confirm_btn_bg.png';
import buyBonusConfirmBtnFrame from '../assets/buy-bonus/confirm_btn_frame.png';
import buyBonusConfirmBtnBuy from '../assets/buy-bonus/confirm_btn_buy.png';
import buyBonusConfirmBtnYes from '../assets/buy-bonus/confirm_btn_yes.png';
import buyBonusConfirmBtnNo from '../assets/buy-bonus/confirm_btn_no.png';
import buyBonusMin from '../assets/buy-bonus/buy_bonus_min.png';
import buyBonusMax from '../assets/buy-bonus/buy_bonus_max.png';

export const buyBonusLoader = (app: Application) => {
	app.loader
		.add('buyBonusBetBg', buyBonusBetBg)
		.add('buyBonusBetFrame', buyBonusBetFrame)
		.add('buyBonusBetInputBg', buyBonusBetInputBg)
		.add('buyBonusBetInputFrame', buyBonusBetInputFrame)
		.add('buyBonusBetInputMinus', buyBonusBetInputMinus)
		.add('buyBonusBetInputPlus', buyBonusBetInputPlus)
		.add('buyBonusBntBg', buyBonusBntBg)
		.add('buyBonusBntFrame', buyBonusBntFrame)
		.add('buyBonusBntTitle', buyBonusBntTitle)
		.add('buyBonusModalTitle', buyBonusModalTitle)
		.add('buyBonusModalCloseBg', buyBonusModalCloseBg)
		.add('buyBonusModalCloseFrame', buyBonusModalCloseFrame)
		.add('buyBonusModalCloseX', buyBonusModalCloseX)
		.add('buyBonusModalBg', buyBonusModalBg)
		.add('buyBonusModalFrame', buyBonusModalFrame)
		.add('buyBonusScatter', buyBonusScatter)
		.add('buyBonusConfirmTitle', buyBonusConfirmTitle)
		.add('buyBonusConfirmBtnBg', buyBonusConfirmBtnBg)
		.add('buyBonusConfirmBtnFrame', buyBonusConfirmBtnFrame)
		.add('buyBonusConfirmBtnBuy', buyBonusConfirmBtnBuy)
		.add('buyBonusConfirmBtnYes', buyBonusConfirmBtnYes)
		.add('buyBonusConfirmBtnNo', buyBonusConfirmBtnNo)
		.add('buyBonusMin', buyBonusMin)
		.add('buyBonusMax', buyBonusMax)
};
