import { Application } from 'pixi.js';
import anteButtonBg from '../assets/ante-btn/button_bg.png';
import anteButtonFrame from '../assets/ante-btn/button_frame.png';
import anteButtonTitle from '../assets/ante-btn/button_title.png';
import anteButtonTogglerFrame from '../assets/ante-btn/toggler_frame.png';
import anteButtonTogglerBg from '../assets/ante-btn/toggler_bg.png';
import anteButtonTogglerOffBtn from '../assets/ante-btn/toggler_off_btn.png';
import anteButtonTogglerOnBtn from '../assets/ante-btn/toggler_on_btn.png';
import anteButtonTogglerLight from '../assets/ante-btn/toggler_light.png';
import { CDN_BASE_URL } from '../Game';
import anteButtonTitlePortrait from '../assets/ante-btn/button_title_portrait.png';

export const anteButtonLoader = (app: Application) => {
	app.loader
		.add('anteButtonBg', anteButtonBg)
		.add('anteButtonFrame', anteButtonFrame)
		.add('anteButtonTitle', anteButtonTitle)
		.add('anteButtonTogglerFrame', anteButtonTogglerFrame)
		.add('anteButtonTogglerOffBtn', anteButtonTogglerOffBtn)
		.add('anteButtonTogglerBg', anteButtonTogglerBg)
		.add('anteButtonTogglerOnBtn', anteButtonTogglerOnBtn)
		.add('anteButtonTogglerLight', anteButtonTogglerLight)
		.add('anteButtonTitlePortrait', anteButtonTitlePortrait)
		.add('anteButtonTogglerFrameAnim', `${CDN_BASE_URL}/spine/ante_btn/button_on_off_anim.json`)
};
