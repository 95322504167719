import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';

class BetButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	buttonDisabled: PIXI.Sprite;
	isModalActive: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.isModalActive = false;
		this.buttonDefault = new PIXI.Sprite(
			this.app.loader.resources['betButtonDefault'].texture!
		);
		this.buttonHover = new PIXI.Sprite(
			this.app.loader.resources['betButtonHover'].texture!
		);
		this.buttonDisabled = new PIXI.Sprite(
			this.app.loader.resources['betButtonDisabled'].texture!
		);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.button.x = 175;
		this.button.y = 758;
		this.button.width = 190;
		this.button.height = 190;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonHover.alpha = 0;
		this.buttonDisabled.alpha = 0;

		this.buttonDefault.anchor.set(0.5);
		this.buttonHover.anchor.set(0.5);
		this.buttonDisabled.anchor.set(0.5);

		this.button.addChild(this.buttonDefault!);
		this.button.addChild(this.buttonHover!);
		this.button.addChild(this.buttonDisabled!);

		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));

		this.game.gameContainer.addChild(this.button);
	};

	leftClick = (e:any) => {
    if(e.data.button === 0){
      this.doPointerDown(this.app)
    };
  }

	closeModal(isActive: boolean) {
		this.isModalActive = isActive;
	}
	changeButtonState(state: boolean){
		this.button.interactive = state;
		this.button.buttonMode = state;
		this.button.alpha= state ? 1 : 0.5;
	}

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonHover,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.isModalActive = !this.isModalActive;
		let wiggleTween = gsap.timeline();
		this.game.soundController.btn_ui?.play()
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default BetButton;
