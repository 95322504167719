import React from 'react';
import ErrorPage from '../features/ErrorPage/ErrorPage';
import HomePage from '../features/HomePage/HomePage';
import Login from '../features/Login/Login';
import Registration from '../features/Registration/Registration';
import TestPage from '../features/TestPage/TestPage';
import UpdateProfile from '../features/UpdateProfile/UpdateProfile';

export interface IRoute {
	path: string;
	element: React.ComponentType;
}

export enum RouteNames {
	LOGIN = '/login',
	REGISTRATION = '/registration',
	UPDATE_USER_DATA = 'update_user',
	ERROR_PAGE = 'error',
	TEST_PAGE = '/test/index.html',
	HOME_PAGE = '/index.html',
}

export const publicRoutes: IRoute[] = [
	{ path: RouteNames.LOGIN, element: Login },
	// { path: RouteNames.REGISTRATION, element: Registration },
	// { path: RouteNames.ERROR_PAGE, element: ErrorPage },
	// { path: RouteNames.TEST_PAGE, element: TestPage },
	// { path: RouteNames.HOME_PAGE, element: HomePage },
];

export const privateRoutes: IRoute[] = [
	{ path: RouteNames.UPDATE_USER_DATA, element: UpdateProfile },
	{ path: RouteNames.ERROR_PAGE, element: ErrorPage },
	// { path: RouteNames.TEST_PAGE, element: TestPage },
	{ path: RouteNames.HOME_PAGE, element: HomePage },
];
