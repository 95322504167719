import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';

class ConfirmBuyButton {
	app: Application;
	game: PixiGame;
	width: number;
	height: number;
	button: PIXI.Container;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	label?: PIXI.Sprite;
	textBuy: PIXI.Texture;
	textYes: PIXI.Texture;
	textNo: PIXI.Texture;
	background: PIXI.Sprite;

	constructor(
		game: PixiGame,
		app: Application,
		width: number,
		height: number,
		label?: string
	) {
		this.game = game;
		this.app = app;
		this.width = width;
		this.height = height;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.background = new PIXI.Sprite(
			this.app.loader.resources['buyBonusConfirmBtnFrame'].texture!
		); 
		this.buttonDefault = new PIXI.Sprite(
			this.app.loader.resources['buyBonusConfirmBtnBg'].texture!
		); 
		this.textBuy = this.app.loader.resources['buyBonusConfirmBtnBuy'].texture!
		this.textYes = this.app.loader.resources['buyBonusConfirmBtnYes'].texture!
		this.textNo = this.app.loader.resources['buyBonusConfirmBtnNo'].texture!
		const currentText = label === "BUY" ? this.textBuy : label === "YES" ? this.textYes : this.textNo;
		this.label = new PIXI.Sprite(currentText);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.button.width = this.width;
		this.button.height = this.height;

		this.background.width = this.width;
		this.background.height = this.height;

		this.buttonDefault.anchor.set(0.5, 0.5);
		this.label!.anchor.set(0.5, 0.5);

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.label!.position.set(0, 0);
		this.buttonDefault!.position.set(this.width / 2, this.height / 2);

		this.button.addChild(this.background);
		this.background.addChild(this.buttonDefault!);
		this.buttonDefault.addChild(this.label!);

		this.button
			.on('pointerdown', () => this.doPointerDown(this.app))
	};

	doPointerDown(app: Application) {
		this.game.soundController.btn_ui?.play()
		this.pointerIsDown = true;
	}
}

export default ConfirmBuyButton;
