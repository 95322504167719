
import { useEffect, useRef, useState } from 'react';
import useHandlerKeyDown from './hooks/useHandlerKeyDown';
import { PixiGame } from './index';
import GameLoaderOverlay from './components/GameLoaderOverlay';
import useUpdateGameBalance from './hooks/useUpdateGameBalance';
import useHandlerGameWin from './hooks/useHandlerGameWin';
import useHandlerInfoBar from './hooks/useHandlerInfoBar';
import Header from '../../components/Header/Header';
import TotalBetModal from '../../components/Modals/TotalBetModal/TotalBetModal';
import AutoPlayModal from '../../components/Modals/AutoPlayModal/AutoPlayModal';
import OutOfMoneyModal from "../../components/Modals/OutOfMoneyModal/OutOfMoneyModal";

import 'pixi-spine'
import '@pixi-spine/loader-3.8';
import {useActions} from "../../hooks/useActions";
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
export * from '@pixi-spine/runtime-3.8';
export * from '@pixi-spine/base';


const game = new PixiGame('default');
const isDev = window.origin.includes('http://localhost:');
export const CDN_BASE_URL = window.origin + `${isDev ? '' : '/square-gummy-bear'}`

function Game() {
	const ref: any = useRef(null);
	const { setAuth, setInfoBar, setTotalBet } = useActions();
	const [isAssetsLoaded, setAssetsLoaded] = useState(false);

	useHandlerKeyDown(game, isAssetsLoaded);

	const { balance, betValue, totalBetValue } = useUpdateGameBalance(
		game,
		isAssetsLoaded
	);
	const { newGameWin, gameWin } = useHandlerGameWin(game);
	useHandlerInfoBar(betValue, gameWin, balance, totalBetValue);



	useEffect(() => {
		document.addEventListener('visibilitychange', function () {
			if (document.hidden) {
				game.soundController.backgroundSound?.mute(true);
				game.soundController.backgroundFSSound?.mute(true);
			} else {
				game.soundController.backgroundSound?.mute(false);
				game.soundController.backgroundFSSound?.mute(false);
			}
		});
	}, []);

	useEffect(() => {
		game.initialize({ref, setInfoBar, setTotalBet, setAuth});

		return () => {
			game?.app?.stop();
		};
	}, [ref]);

	return (
		<>
			<Header game={game} />
			<div
				ref={ref}
				style={{
					display: isAssetsLoaded ? 'flex' : 'none',
					justifyContent: 'center',
				}}
			></div>
			{!isAssetsLoaded && (
				<GameLoaderOverlay
					game={game}
					setAssetsLoaded={setAssetsLoaded}
				/>
			)}
			<div>
				<TotalBetModal game={game} />
				<AutoPlayModal game={game} />
				<OutOfMoneyModal game={game} />
			</div>
		</>
	);
}

export default Game;
