import { FC } from "react";
import styled from "styled-components";
import Api from "./api";
import AppRouter from "./components/AppRouter/AppRouter";
import {LocalStorage} from "./utils/localStorage";


export const api = new Api();

LocalStorage.checker()

const App: FC = () => {
    return (
    <Wrapper>
      <AppRouter />
    </Wrapper>
  );
};

export default App;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: 100vh;

	main {
		flex: 1 1 auto;
	}
`;
