import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';

class PlayButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonBackground: PIXI.Sprite;
	buttonBorder: PIXI.Sprite;
	buttonBorderFS: PIXI.Sprite;
	buttonLabel: PIXI.Text;
	buttonPlay: PIXI.Sprite;
	buttonPlayHover: PIXI.Sprite;
	buttonStop: PIXI.Sprite;
	buttonStopHover: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.button = new PIXI.Container();
		this.buttonBackground = new PIXI.Sprite(
			this.app.loader.resources['playButtonBackground'].texture!
		);
		this.buttonBorder = new PIXI.Sprite(
			this.app.loader.resources['playButtonBorder'].texture!
		);
		this.buttonBorderFS = new PIXI.Sprite(
			this.app.loader.resources['playButtonBorderFS'].texture!
		);
		this.buttonPlay = new PIXI.Sprite(
			this.app.loader.resources['playButtonPlay'].texture!
		);
		this.buttonPlayHover = new PIXI.Sprite(
			this.app.loader.resources['playButtonPlayHover'].texture!
		);
		this.buttonStop = new PIXI.Sprite(
			this.app.loader.resources['playButtonStop'].texture!
		);
		this.buttonStopHover = new PIXI.Sprite(
			this.app.loader.resources['playButtonStopHover'].texture!
		);
		this.buttonLabel = new PIXI.Text('', {
			fontFamily: 'Rubik',
			fontSize: 55.5,
			fontWeight: '900',
			fill: '#F9AD29',
			stroke: '#000000',
    	strokeThickness: 1,
		});
	}

	initialize = () => {
		this.button.x = 1920 - 74 - 220 / 2;
		this.button.y = 1080 - 460 - 220 / 2;
		this.button.width = 220;
		this.button.height = 220;
		this.button.hitArea = new PIXI.Circle(0,0, 110)

		this.buttonBackground.y = 0;
		this.buttonBackground.x = 0;

		this.buttonBorder.x = 0;
		this.buttonBorder.y = 0;
		this.buttonBorder.width = 200;
		this.buttonBorder.height = 200;

		this.buttonBorderFS.x = 0;
		this.buttonBorderFS.y = 0;
		this.buttonBorderFS.width = 200;
		this.buttonBorderFS.height = 200;
		this.buttonBorderFS.alpha = 0;

		this.buttonLabel.anchor.set(0.5);

		this.buttonPlay.x = 0;
		this.buttonPlay.y = 0;

		this.buttonPlayHover.x = 0;
		this.buttonPlayHover.y = 0;
		this.buttonPlayHover.alpha = 0;

		this.buttonBackground.anchor.set(0.5);
		this.buttonPlay.anchor.set(0.5);
		this.buttonPlayHover.anchor.set(0.5);
		this.buttonBorder.anchor.set(0.5);
		this.buttonBorderFS.anchor.set(0.5);

		this.game.gameContainer.addChild(this.button!);
		this.button.addChild(this.buttonBackground!);
		this.button.addChild(this.buttonBorder!);
		this.button.addChild(this.buttonBorderFS!);
		this.button.addChild(this.buttonLabel!);
		this.button.addChild(this.buttonPlay!);
		this.button.addChild(this.buttonPlayHover!);

		this.button.interactive = true;
		this.button.buttonMode = true;
		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
			this.setButtonState(false)
		};

	leftClick = (e:any) => {
		if(e.data.button === 0){
			this.doPointerDown(this.app)
		};
	}

	setButtonState = (isActive: boolean) => {
		if (isActive) {
			this.toStopMode();
			this.button
				.removeListener('touchstart')
				.removeListener('click')
				.on('touchstart', () => {
					this.stopAutoPlay();
				})
				.on('click', () => {
					this.stopAutoPlay();
				})
		} else {
			this.toPlayMode();
			if (this.game.type === 'default' || this.game.type === 'doubleBonusGame' || this.game.type === 'debug') {
				this.button
					.removeListener('touchstart')
					.removeListener('click')
					.on('touchstart', () => this.game.slotMachine?.onSpin({isBonusGame: false}))
					.on('click', (e) => this.leftClickOnSpin(e))
			}
		}
	};

	leftClickOnSpin = (e:any) => {
		if(e.data.button === 0){
			this.game.slotMachine?.onSpin({isBonusGame: false, isDoubleBonusGame: false})
		};
	}

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonPlay,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonPlayHover,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonPlay,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		let wiggleTween = gsap.timeline();

		wiggleTween.fromTo(
			this.buttonPlayHover,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);

		this.game.slotMachine?.onSpin({isBonusGame: false, isDoubleBonusGame: false})
		this.game.assetsController?.autoPlayModal?.showModal(false);
		this.pointerIsDown = true;
		this.button
			.on('touchstart', () => this.setButtonState(false))
			.on('click', (e) => this.leftClickSetButtonState(e))
	}

	leftClickSetButtonState = (e:any) => {
		if(e.data.button === 0){
			this.setButtonState(false)
		};
	}

	doPointerOver(app: Application) {
		this.game.soundController.btn_hover_on?.play()
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonBorder,
			0.3,
			{ rotation: this.buttonBorder.rotation },
			{ rotation: this.buttonBorder.rotation + Math.PI, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonPlayHover,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		this.game.soundController.btn_hover_off?.play()
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonBorder,
			0.3,
			{ rotation: this.buttonBorder.rotation },
			{ rotation: this.buttonBorder.rotation - Math.PI, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonPlayHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonPlay,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}

	stopAutoPlay() {
		this.game.assetsController!.autoPlayModal!.isAutoPlayRunning = false;
		this.game.assetsController?.autoPlayModal?.startAutoPlay('OFF')
		this.game.slotMachine?.changeButtonState(false);
		this.game.slotMachine?.getCurrentSpeed(true);
	};

	toPlayMode() {
		this.buttonPlay.alpha = 1
		this.buttonPlayHover.alpha = 1
		this.buttonPlay.texture = this.app.loader.resources['playButtonPlay'].texture!
		this.buttonPlay.width = 41.88;
		this.buttonPlay.height = 46.82;
		this.buttonPlayHover.texture = this.app.loader.resources['playButtonPlayHover'].texture!
		this.buttonPlayHover.width = 61.87;
		this.buttonPlayHover.height = 66.85;
	};

	toStopMode() {
		this.buttonPlay.texture = this.buttonStop.texture;
		this.buttonPlay.width = 65;
		this.buttonPlay.height = 65;
		this.buttonPlayHover.texture = this.buttonStopHover.texture;
		if(this.game.assetsController?.isFs){
			this.buttonPlay.visible = false
			this.buttonPlayHover.visible = false
		} else {
			this.buttonPlay.visible = true
			this.buttonPlayHover.visible = true
		}
		this.buttonPlayHover.width = 65;
		this.buttonPlayHover.height = 65;
	};

	changeAplhaAndButtonMode(isActive: boolean) {
		if(isActive) {
			this.button.alpha = 0.5;
			this.button.interactive = false;
			this.button.buttonMode = false;
		} else {
			this.button.alpha = 1;
			this.button.interactive = true;
			this.button.buttonMode = true;
		}
	}
	setSpinCounter = (value: number | string) => {
		this.buttonLabel.text = !value ? 0 : value;
		this.buttonLabel.alpha = 1;
	};
}

export default PlayButton;
