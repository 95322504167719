import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import {
	autoPlayItemFooterTitle,
	autoPlayModalTitle,
	playButtonLabel,
} from '../../../../styles/pixiStyles';
import { PixiGame } from '../../index';
import Limit from './Limit';
import NumberOfSpins from './NumberOfSpins';
import StopAutoPlay from './StopAutoPlay';
import {onSpinResponse} from "../../types/slotMachine";
import { EventType, getEvent } from '../../../../GameEventBus';

class AutoPlayModal {
	app: Application;
	game: PixiGame;
	modal: PIXI.Container;
	buttons?: any[];
	background: PIXI.Graphics;
	advancedSettingsBackground: PIXI.Graphics;
	playButton: PIXI.Sprite;
	playButtonLabel: PIXI.Text;
	title: PIXI.Text;
	closeButton: PIXI.Sprite;
	advancedOpenButton: PIXI.Sprite;
	advancedCloseButton: PIXI.Sprite;
	footerTitle: PIXI.Text;
	numberOfSpins?: NumberOfSpins;
	lossLimit?: Limit;
	lossLimitValue?: number;
	singleWinLimit?: Limit;
	singleWinLimitValue?: number;
	stopAutoPlay?: StopAutoPlay;
	stopSpin: boolean;
	onStop?: onSpinResponse;
	winResult: number;
	totalBet: number;
	stopOnAnyWin?: boolean;
	stopIsFreeSpinWon?: boolean;
	isAutoPlayRunning?: boolean;
	constructor(game: PixiGame, app: Application) {
		this.isAutoPlayRunning = false;
		this.game = game;
		this.app = app;
		this.buttons = [];
		this.modal = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.advancedSettingsBackground = new PIXI.Graphics();
		this.advancedOpenButton = new PIXI.Sprite(
			this.app.loader.resources['autoPlayAdvancedOpenButton'].texture!
		);
		this.advancedCloseButton = new PIXI.Sprite(
			this.app.loader.resources['autoPlayAdvancedCloseButton'].texture!
		);
		this.title = new PIXI.Text('AUTO PLAY', autoPlayModalTitle);
		this.footerTitle = new PIXI.Text(
			'ADVANCED SETTINGS',
			autoPlayItemFooterTitle
		);
		this.playButton = new PIXI.Sprite(
			this.app.loader.resources['autoPlayModalLogo'].texture!
		);
		this.playButtonLabel = new PIXI.Text('', playButtonLabel);
		this.closeButton = new PIXI.Sprite(
			this.app.loader.resources['autoPlayCloseButton'].texture!
		);
		this.stopSpin = true;
		this.winResult = 0;
		this.totalBet = 0;
	}

	initialize = async () => {
		this.modal.visible = false;
		this.modal.zIndex = 1000;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 304, 207, 15);
		this.background.endFill();

		this.advancedSettingsBackground.beginFill(0x151515);
		this.advancedSettingsBackground.drawRoundedRect(0, 0, 304, 536, 15);
		this.advancedSettingsBackground.endFill();
		this.advancedSettingsBackground.visible = false;

		this.playButton.x = 304 - 60 - 8;
		this.playButton.y = 8;
		this.playButton.width = 60;
		this.playButton.height = 60;
		this.playButton.interactive = true;
		this.playButton.buttonMode = true;

		this.playButtonLabel.anchor.set(0.5)
		this.playButtonLabel.x = 32;
		this.playButtonLabel.y = 30;

		this.title.x = 94;
		this.title.y = 26;

		this.footerTitle.x = 95;
		this.footerTitle.y = 175.5;

		this.closeButton.x = 8;
		this.closeButton.y = 24.5;
		this.closeButton.width = 33;
		this.closeButton.height = 27;
		this.setButtonState(this.closeButton, true);

		this.advancedOpenButton.x = 259;
		this.advancedOpenButton.y = 170;
		this.advancedOpenButton.width = 33;
		this.advancedOpenButton.height = 22;
		this.setButtonState(this.advancedOpenButton, true);

		this.advancedCloseButton.x = 259;
		this.advancedCloseButton.y = 170;
		this.advancedCloseButton.width = 33;
		this.advancedCloseButton.height = 22;
		this.setButtonState(this.advancedCloseButton, true);
		this.advancedCloseButton.alpha = 0;

		this.closeButton.on('touchstart', () => this.showModal(false));
		this.closeButton.on('click', (e) => this.leftClickCloseButton(e));

		this.playButton.on('touchstart', () => this.startAutoPlay(this.playButtonLabel.text));
		this.playButton.on('click', (e) => this.leftClickPlayButton(e));

		this.advancedOpenButton.on('touchstart', () =>
			this.showAdvancedSettings(true)
		);
		this.advancedOpenButton.on('click', (e) =>
			this.leftClickAdvancedOpenButton(e)
		);

		this.advancedCloseButton.on('touchstart', () =>
			this.showAdvancedSettings(false)
		);
		this.advancedCloseButton.on('click', (e) =>
			this.leftClickAdvancedCloseButton(e)
		);

		this.modal.addChild(this.background!);
		this.modal.addChild(this.advancedSettingsBackground!);
		this.modal.addChild(this.playButton!);
		this.playButton.addChild(this.playButtonLabel!);
		this.modal.addChild(this.title!);
		this.modal.addChild(this.footerTitle!);
		this.modal.addChild(this.advancedCloseButton!);
		this.modal.addChild(this.advancedOpenButton!);
		this.modal.addChild(this.closeButton!);
		this.game.gameContainer.addChild(this.modal!);

		if (window.innerHeight >= 1000 && window.innerWidth >= 1920) {
			this.game.assetsController?.autoPlayModal?.changeAutoPlayModalScale(1.5, 1.5);
			this.game.assetsController?.autoPlayModal?.changeAutoPlayModalPosition(this.game.assetsController.reelsContainer?.container!.x! + this.game.assetsController.reelsContainer?.container.width! / 2 - this.game.assetsController.autoPlayModal?.modal.width / 1.7, 50);
	}

		await this.setupButtons();
	};

	leftClickCloseButton = (e:any) => {
    if(e.data.button === 0){
      this.showModal(false)
    }
  }

	leftClickPlayButton = (e:any) => {
    if(e.data.button === 0){
      this.startAutoPlay(this.playButtonLabel.text)
    }
  }

	leftClickAdvancedOpenButton = (e:any) => {
    if(e.data.button === 0){
      this.showAdvancedSettings(true)
    }
  }

	leftClickAdvancedCloseButton = (e:any) => {
    if(e.data.button === 0){
      this.showAdvancedSettings(false)
    }
  }

	setButtonState(button: any, isActive: boolean) {
		button.interactive = isActive;
		button.buttonMode = isActive;
	}

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modal.visible = true;
		} else {
			this.modal.visible = false;
			this.showAdvancedSettings(false);
			this.game.assetsController?.autoPlayButton?.closeModal(false);
		}
	};

	startAutoPlay = (value: string | number) => {
		this.game.slotMachine?.changeBetButtonsState(true)
		if(value === 'OFF') {
			this.isAutoPlayRunning = false;
			this.stopSpin = true;
			this.playButtonLabel.alpha = 0;
			this.playButtonLabel.text = '';
			this.game.assetsController?.autoPlayButton?.setSpinCounter(value)
			this.game.assetsController?.autoPlayButton?.setButtonState(false)
			this.game.assetsController?.playButton?.setButtonState(false);
			getEvent(EventType.GAME_AUTO_SPIN_COUNT).send('');
			return;
		} else if (value === 'ထ') {
			this.isAutoPlayRunning = true;
			this.playButtonLabel.alpha = 1;
			this.stopSpin = false;
			this.winResult = 0;
			this.totalBet = 0;
			this.setCounter(Infinity);
			this.game.assetsController?.autoPlayButton?.setSpinCounter('ထ');
			this.game.assetsController?.autoPlayButton?.setButtonState(true);
			this.game.assetsController?.playButton?.setButtonState(true);
			this.showModal(false);
		} else if (Number(value) > 0) {
			this.isAutoPlayRunning = true;
			this.playButtonLabel.alpha = 1;
			this.stopSpin = false;
			this.winResult = 0;
			this.totalBet = 0;
			this.setCounter(Number(value));
			this.game.assetsController?.autoPlayButton?.setSpinCounter(Number(value));
			this.game.assetsController?.autoPlayButton?.setButtonState(true);
			this.game.assetsController?.playButton?.setButtonState(true);
			this.showModal(false);
		}
	}

	setCounter = (value: number, count: number = 0) => {
		if (this.stopSpin || !this.game.slotMachine) return;
		if (value > count) {
			const timer: any = setTimeout(async() => {
				if(this.stopSpin) return clearTimeout(timer);
				value-=1
				this.totalBet += 1
				if(value === Infinity) {
					this.playButtonLabel.text = 'ထ';
					this.game.assetsController?.autoPlayButton?.setSpinCounter('ထ')
				} else {
					this.playButtonLabel.text = value === 0 ? '' : value;
					this.game.assetsController?.autoPlayButton?.setSpinCounter(value);
					getEvent(EventType.GAME_AUTO_SPIN_COUNT).send(value === 0 ? '' : value);
				}
				const res = await this.game.slotMachine?.onSpin({losslimit: this.lossLimitValue, singleWinLimit: this.singleWinLimitValue, stopIsFreeSpinWon:this.stopIsFreeSpinWon, stopOnAnyWin:this.stopOnAnyWin});

				if (
					res === onSpinResponse.FREE_SPIN_WON ||
					res === onSpinResponse.STOP_AUTOSPIN
				) {
					this.game.assetsController?.autoPlayButton?.setButtonState(false);
					this.game.assetsController?.playButton?.setButtonState(false);
					this.stopSpin = true
					this.isAutoPlayRunning = false;
					this.game.slotMachine?.changeButtonState(false);
					return clearTimeout(timer)
				}
				if (this.playButtonLabel.text === 'ထ') {
					this.setCounter(Infinity)
				} else if (this.playButtonLabel.text ==='OFF'){
					this.startAutoPlay('OFF')
				}else if(Number(this.playButtonLabel.text) !== value) {
					this.setCounter(Number(this.playButtonLabel.text))
				} else {
					this.setCounter(value)
					if(value === 0 ){
						this.game.assetsController!.autoPlayModal!.isAutoPlayRunning = false;
					}
				}
			}, 1000 * this.game.slotMachine.currentSpeed);
		} else {
			this.game.assetsController?.autoPlayButton?.setButtonState(false)
			this.game.assetsController?.playButton?.setButtonState(false)
			this.stopSpin = true
			this.isAutoPlayRunning = false;
			this.game.slotMachine?.changeButtonState(false);
		}
	}

	setSpinCounter = (value: string | number) => {
			this.playButtonLabel.alpha = 1;
			this.playButtonLabel.text = value;
	}

	setLossLimitValue = (value: string) =>{
		switch (value){
			case '5x':
				this.lossLimitValue = 5
				break;
			case '20x':
				this.lossLimitValue = 20
				break;
			case '50x':
				this.lossLimitValue = 50
				break;
			case '75x':
				this.lossLimitValue = 75
				break;
			case '100x':
				this.lossLimitValue = 100
				break;
			default:
				this.lossLimitValue = 0
				break;
		}
	}
	setSingleWinLimitValue = (value: string) =>{
		switch (value){
			case '5x':
				this.singleWinLimitValue = 5
				break;
			case '20x':
				this.singleWinLimitValue = 20
				break;
			case '50x':
				this.singleWinLimitValue = 50
				break;
			case '75x':
				this.singleWinLimitValue = 75
				break;
			case '100x':
				this.singleWinLimitValue = 100
				break;
			default:
				this.singleWinLimitValue = 0
				break;
		}
	}
	setStopOnAnyWin = (check: boolean) => {
		this.stopOnAnyWin = check
	}
	setStopIsFreeSpinWon = (check: boolean) => {
		this.stopIsFreeSpinWon = check
	}

	setupButtons = async () => {
		this.numberOfSpins = new NumberOfSpins(this.game, this.app);
		this.stopAutoPlay = new StopAutoPlay(this.game, this.app);
		this.lossLimit = new Limit(this.game, this.app, 'LOSS LIMIT');
		this.singleWinLimit = new Limit(this.game, this.app, 'SINGLE WIN LIMIT');

		this.numberOfSpins.container.x = 8;
		this.numberOfSpins.container.y = 80;

		this.stopAutoPlay.container.x = 8;
		this.stopAutoPlay.container.y = 212;

		this.lossLimit.container.x = 8;
		this.lossLimit.container.y = 332;

		this.singleWinLimit.container.x = 8;
		this.singleWinLimit.container.y = 437;

		this.stopAutoPlay.container.visible = false;
		this.lossLimit.container.visible = false;
		this.singleWinLimit.container.visible = false;

		this.buttons?.push(this.stopAutoPlay.container);
		this.buttons?.push(this.lossLimit.container);
		this.buttons?.push(this.singleWinLimit.container);

		this.modal.addChild(this.numberOfSpins.container!);
		this.modal.addChild(this.stopAutoPlay.container!);
		this.modal.addChild(this.lossLimit.container!);
		this.modal.addChild(this.singleWinLimit.container!);

		await this.numberOfSpins.initialize();
		await this.stopAutoPlay.initialize();
		await this.lossLimit.initialize();
		await this.singleWinLimit.initialize();
	};

	showAdvancedSettings(isActive: boolean) {
		if (isActive) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.advancedOpenButton,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.advancedCloseButton,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.stopAutoPlay?.container!,
				0,
				{ alpha: 1 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.lossLimit?.container!,
				0,
				{ alpha: 1 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.singleWinLimit?.container!,
				0,
				{ alpha: 1 },
				{ alpha: 1, repeat: 0 }
			);
			this.buttons?.forEach(element => {
				element.visible = true;
			});
			this.advancedSettingsBackground.visible = true;
			this.advancedOpenButton.visible = false;
			this.advancedCloseButton.visible = true;
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.advancedOpenButton,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.advancedCloseButton,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.stopAutoPlay?.container!,
				0,
				{ alpha: 0 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.lossLimit?.container!,
				0,
				{ alpha: 0 },
				{ alpha: 0, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.singleWinLimit?.container!,
				0,
				{ alpha: 0 },
				{ alpha: 0, repeat: 0 }
			);
			this.buttons?.forEach(element => {
				element.visible = false;
			});
			this.advancedSettingsBackground.visible = false;
			this.advancedOpenButton.visible = true;
			this.advancedCloseButton.visible = false;
		}
	}

	changeAutoPlayModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeAutoPlayModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}

	changeAutoPlayModalSize(x: number, y: number) {
		if (!this.modal) return;
		this.modal.width = x;
		this.modal.height = y;
	}
}

export default AutoPlayModal;
