import * as PIXI from 'pixi.js';
import '../../../../styles/index.css';

export const anteValueStyles = new PIXI.TextStyle({
    fontFamily: 'Superfats',
    fontSize: 23,
    fontWeight: '400',
    fill: ['#fdb40a', '#fdb40a', '#ffce0e', '#ffef26'],
    fillGradientStops: [1, 0.73, 0.43, 0],
    dropShadow: true,
    dropShadowColor: "#703318",
    dropShadowBlur: 0,
    dropShadowDistance: 4,
    dropShadowAngle: 102.1,
    strokeThickness: 3,
    stroke: "#703318",
});