import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, filters } from 'pixi.js';
import { PixiGame } from '../../index';
import { anteValueStyles } from './style';
import { Spine } from 'pixi-spine';

class DoubleBonusChanceButton {
	app: Application;
	game: PixiGame;
	button: PIXI.Container;
	buttonFrame: PIXI.Sprite;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonBg?: Spine;
	buttonText: PIXI.Sprite;
	togglerFrame: PIXI.Sprite;
	buttonLabel: PIXI.Text;
	isDBCActive?: boolean;
	togglerBg: PIXI.Sprite;
	togglerBtnOn: PIXI.Sprite;
	togglerBtnOff: PIXI.Sprite;
	togglerLight: PIXI.Sprite;
	buttonTextPortrait: PIXI.Sprite;
	colorFilter = new PIXI.filters.ColorMatrixFilter();

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		this.app = app;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.buttonBg = new Spine(this.app.loader.resources['anteButtonTogglerFrameAnim'].spineData!);
		this.buttonFrame = new PIXI.Sprite(
			this.app.loader.resources['anteButtonFrame'].texture!
		);
		this.buttonLabel = new PIXI.Text(
			`${(this.game.slotMachine!.betValue || 1).toFixed(2)} USD`,
			anteValueStyles
		);
		this.buttonText = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTitle'].texture!
		);
		this.togglerFrame = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTogglerFrame'].texture!
		);
		this.togglerBg = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTogglerBg'].texture!
		);
		this.togglerBtnOn = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTogglerOnBtn'].texture!
		);
		this.togglerBtnOff = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTogglerOffBtn'].texture!
		);
		this.button = new PIXI.Container();
		this.togglerLight = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTogglerLight'].texture!
		);
		this.buttonTextPortrait = new PIXI.Sprite(
			this.app.loader.resources['anteButtonTitlePortrait'].texture!
		);
		this.togglerBtnOn.filters = [this.colorFilter];
	}

	initialize = async () => {
		this.setupToggler();
		this.isDBCActive = false;
		this.button.x = 212;
		this.button.y = 668;
		this.button.width = 220;
		this.button.height = 268;

		this.buttonFrame.x = 0;
		this.buttonFrame.y = 0;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.buttonLabel.anchor.set(0.5);
		this.buttonFrame.anchor.set(0.5);
		this.buttonText.anchor.set(0.5);
		this.buttonTextPortrait.anchor.set(0.5);
		
		this.buttonLabel.position.set(0, -87);
		this.buttonText.position.set(0, -10);
		
		this.button.addChild(this.buttonFrame);
		this.buttonFrame.addChild(this.buttonBg!);
		this.buttonFrame.addChild(this.buttonText);
		this.buttonFrame.addChild(this.buttonLabel);
		this.buttonFrame.addChild(this.togglerFrame);
		this.buttonFrame.addChild(this.buttonTextPortrait);

		this.button
			.on('pointerup', e => this.leftClickPointerUp(e))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchend', () => this.doPointerUp(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', e => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));

		this.game.gameContainer.addChild(this.button);
	};

	setupToggler = () => {
		this.togglerFrame.position.set(0, 80);
		this.togglerBtnOff.position.set(0, 0);
		this.togglerBtnOn.position.set(-22, 0);

		this.togglerFrame.anchor.set(0.5);
		this.togglerBg.anchor.set(0.5);
		this.togglerBtnOn.anchor.set(0.5);
		this.togglerBtnOff.anchor.set(0.5);
		this.togglerLight.anchor.set(0.5);
		this.colorFilter.saturate(-0.5, false);
		this.togglerFrame.addChild(this.togglerBg);
		this.togglerFrame.addChild(this.togglerLight);
		this.togglerBg.addChild(this.togglerBtnOn);
		this.togglerBg.addChild(this.togglerBtnOff);
		
		this.togglerBtnOff.alpha = 0;
	}

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.doPointerDown(this.app);
		}
	};

	leftClickPointerUp = (e: any) => {
		if (e.data.button === 0) {
			this.doPointerUp(this.app);
		}
	};

	changeButtonState(state: boolean) {
		this.button.interactive = state;
		this.button.buttonMode = state;
		this.button.alpha = state ? 1 : 0.5;
	}

	doPointerUp = (app: Application) => {
		
		if (this.pointerIsDown) {
			if (this.isDBCActive === true) {
				this.game.type = 'doubleBonusGame';
				this.game.slotMachine?.changeBetValue(
					this.game.slotMachine!.betValue * 1.25
				);
				this.game.assetsController?.betInput?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController?.betInputPortret?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController?.betInputLandscape?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(
					false
				);
				this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
				this.colorFilter.saturate(0, false);
				gsap.to(
					this.togglerBtnOn, {x: 22, duration: 0.3}
				);
			} else {
				this.colorFilter.saturate(-0.5, false);
				gsap.to(
					this.togglerBtnOn, {x: -22, duration: 0.2}
				);
				this.game.type = 'default';
				this.game.slotMachine?.changeBetValue(
					this.game.slotMachine!.betValue / 1.25
				);
				this.game.assetsController?.betInput?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController?.betInputPortret?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController?.betInputLandscape?.changeValue(
					this.game.slotMachine!.betValue
				);
				this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(
					true
				);
				this.game.assetsController!.buyBonusButton!.button.alpha = 1;
			}
		}
		this.game.assetsController?.buyBonusModal?.changeMaxCost(
			this.game.slotMachine!.betValue
		);
		this.game.assetsController?.buyBonusModal?.changeMinCost(
			this.game.slotMachine!.betValue
		);
		// this.game.assetsController?.buyBonusModalPortrait?.changeMaxCost(
		// 	this.game.slotMachine!.betValue
		// );
		// this.game.assetsController?.buyBonusModalPortrait?.changeMinCost(
		// 	this.game.slotMachine!.betValue
		// );
		this.game.assetsController?.buyMinBonusModal?.changeMinCost(
			this.game.slotMachine!.betValue
		);
		// this.game.assetsController?.buyMinBonusModalPortrait?.changeMinCost(
		// 	this.game.slotMachine!.betValue
		// );
		this.game.assetsController?.buyMaxBonusModal?.changeMaxCost(
		this.game.slotMachine!.betValue
		);
		// this.game.assetsController?.buyMaxBonusModalPortrait?.changeMaxCost(
		// 	this.game.slotMachine!.betValue
		// );
		this.changeValue(this.game.slotMachine!.betValue)
		this.pointerIsDown = false;
	};

	doPointerUpOutside(app: Application) {
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		this.game.soundController.btn_ui?.play();
		this.pointerIsDown = true;
		this.isDBCActive = !this.isDBCActive;
	}

	doPointerOver(app: Application) {
		this.game.soundController.bonus_hover?.play();
		this.pointerIsOver = true;
		this.buttonBg?.state.setAnimation(1, "on", true);
	}

	doPointerOut(app: Application) {
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
			this.buttonBg?.state.setAnimation(1, "off", true);
		}
	}

	changeValue = (value: number) => {
		this.buttonLabel.text = `${value.toFixed(2)} USD`;
	};

	doDefaultVisible(app: Application) {
		let wiggleTween = gsap.timeline();
		if (this.isDBCActive === false) {
			wiggleTween.to(this.togglerBtnOn, 0, { alpha: 0 });
			wiggleTween.to(this.togglerBtnOff, 1, { alpha: 1 });
		} else {
			wiggleTween.to(this.togglerBtnOn, 1, { alpha: 1 });
			wiggleTween.to(this.togglerBtnOff, 0, { alpha: 0 });
		}
	}

	toPortrait = (isPortrait: boolean) => {
		this.buttonTextPortrait.visible = isPortrait ? true : false;
		this.buttonText.visible = isPortrait ? false : true;
		this.button.angle = isPortrait ? 90 : 0;
		this.buttonTextPortrait.angle = isPortrait ? 270 : 0;
		this.buttonLabel.angle = isPortrait ? 270 : 0
		this.togglerFrame.angle = isPortrait ? 270 : 0
		if(isPortrait) {
			this.togglerFrame.position.set(60, 0);
			this.buttonLabel.position.set(-60, 0);
			this.buttonTextPortrait.position.set(-5, 0);
		} else {
			this.togglerFrame.position.set(0, 80);
			this.buttonLabel.position.set(0, -87);
			this.buttonTextPortrait.position.set(0, -10);
		}
	}
}

export default DoubleBonusChanceButton;
